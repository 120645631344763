import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/global/seo"

export default function ThankYouPage() {
  return (
    <Layout>
      <Seo
        title="Повідомлення успішно відправлено на taho.org.ua"
        description="Ви успішно відправили повідомлення на taho.org.ua. Дякуємо!"
        noindex={true}
      />
      <main className="mx-auto max-w-7xl px-4 sm:my-12 sm:px-6 md:my-16 lg:my-20 lg:px-8 xl:my-28">
        <div className="sm:text-center lg:text-left py-12 sm:py-24">
          <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
            <span className="block text-yellow-600 xl:inline">Вітаємо!</span>{" "}
            <span className="block xl:inline">
              Повідомлення успішно відправлено.
            </span>
          </h1>
          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
            Дякуємо вам за ваше повідомлення. Якщо ви залишили свої контакти, ми
            зв'яжемося з вами, як тільки підготуємо відповідь.
          </p>
          <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
            <div className="rounded-md shadow">
              <Link
                to="/"
                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700 md:py-4 md:text-lg md:px-10"
                replace
              >
                На головну
              </Link>
            </div>
            {/* <div className="mt-3 sm:mt-0 sm:ml-3">
              <Link
                to="/kontakty/"
                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-yellow-700 bg-yellow-100 hover:bg-yellow-200 md:py-4 md:text-lg md:px-10"
                replace
              >
                Зв’язатись
              </Link>
            </div> */}
          </div>
        </div>
      </main>
    </Layout>
  )
}
